import React, { useLayoutEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import BigHeaderImage from "../components/header/bigHeader/bigHeaderImage"
import logo from "../../static/logo-bje.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import SEO from "../components/seo"
import KontaktFormDefault from "../components/kontakt/kontaktFormDefault"
import Headline from "../components/headline"
import * as ffsStyles from "../components/sectionWrap/sectionWrap.module.css"
import SectionWrap from "../components/sectionWrap"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import CTAKontaktmodul from "../components/ctaKontaktmodul"
import MailchimpSignupForm from "../components/mailchimp/MailchimpSignupForm"

const Kontakt = () => {

  const data = useStaticQuery(graphql`
    query {

        ogimage: file(relativePath: { eq: "images/marketinginsights/marketinginsights-titel.jpg" }) {
            childImageSharp {
                fixed(height: 400) {
                    src
                    height
                    width
                }
            }
        }
    }
  `)    

  // reset scroll trigger damit seite nicht irgendwo hinspringt
  useLayoutEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh(true)
    }, 250);
  }, []);

  return (
    <Layout menueDistance>

<SEO
  title="marketinginsights"
  description="Abonnieren Sie marketinginsights – den monatlichen Fach-Newsletter mit fundierten Einblicken zu Marketing, Vertrieb und Digitalisierung für Führungskräfte."
  image={data?.ogimage?.childImageSharp?.fixed}
/>  

<StaticImage
  src="../images/marketinginsights/marketinginsights-header.jpg"
  alt="Logo des Newsletters marketinginsights"
  placeholder="blurred"
  layout="constrained"
  quality={90}
/>

<div className="container">        
  <SectionWrap bgCol={"#ffffff"} pin={false}>

    <div className={`row ${ffsStyles.vcenterRowContent}`}>

      <div className="col-12 px20 mt-5 mb-4">      
        <h2>Expertise für Entscheider: Unser <span style={{color:'#ff6600'}}>marketing</span><strong>insights</strong> Newsletter</h2>  
        <p>Unser monatlicher Newsletter <span style={{color:'#ff6600'}}>marketing</span><strong>insights</strong> bietet Ihnen jeden zweiten Donnerstag im Monat fundierte Einblicke zu aktuellen Schwerpunktthemen aus Marketing, Vertrieb und Digitalisierung. Jede Ausgabe wird durch ein ausführliches Whitepaper ergänzt, das tiefergehende Analysen und praxisnahe Handlungsempfehlungen liefert.
        Profitieren Sie von unserer Expertise und bleiben Sie informiert über neueste Trends und Strategien – speziell aufbereitet für Marketingverantwortliche und Führungskräfte aus dem Vertrieb.</p>
      </div>

      <div className="col-12 px20">
        <h2>Newsletter abonnieren</h2>        
      </div>

      <div className="col-12 px20 mb-5">
        <MailchimpSignupForm />
      </div>
    </div>

  </SectionWrap>
</div>      

    </Layout>
  )
}

export default Kontakt