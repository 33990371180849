import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const MailchimpSignupForm = () => {
  // Zustandsvariablen für Formularfelder
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [anrede, setAnrede] = useState("");
  
  useEffect(() => {
    // MailChimp Validierungs-Script laden
    const script = document.createElement("script");
    script.src = "//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js";
    script.async = true;
    document.body.appendChild(script);

    // MailChimp Validierungs-Funktion initialisieren
    window.fnames = new Array();
    window.ftypes = new Array();
    window.fnames[0] = "EMAIL";
    window.ftypes[0] = "email";
    window.fnames[1] = "FNAME";
    window.ftypes[1] = "text";
    window.fnames[2] = "LNAME";
    window.ftypes[2] = "text";
    window.fnames[5] = "ANREDE";
    window.ftypes[5] = "text";
    window.fnames[3] = "ADDRESS";
    window.ftypes[3] = "address";
    window.fnames[4] = "PHONE";
    window.ftypes[4] = "phone";
    window.fnames[6] = "MMERGE6";
    window.ftypes[6] = "text";

    // Aufräumen beim Komponentenabbau
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Helmet>
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
          rel="stylesheet"
          type="text/css"
        />
        <style type="text/css">{`        
          #mc_embed_signup {
            background: #fff;
            clear: left;
            font: 14px Helvetica, Arial, sans-serif;
            // width: 600px;
            max-width: 100%;            
          }
          #mc_embed_signup form{            
            margin:0!important;
          }            
          #mc-embedded-subscribe-form input[type=checkbox] {
            display: inline;
            width: auto;
            margin-right: 10px;
          }
          #mergeRow-gdpr {
            margin-top: 20px;
          }
          #mergeRow-gdpr fieldset label {
            font-weight: normal;
          }
          #mc-embedded-subscribe-form .mc_fieldset {
            border: none;
            min-height: 0px;
            padding-bottom: 0px;
          }
        `}</style>
      </Helmet>
      
      <div id="mc_embed_signup">
        <form
          action="https://Qmarketing.us7.list-manage.com/subscribe/post?u=b3cb9b6cd4aece0744754a36c&amp;id=3730d8c389&amp;v_id=5010&amp;f_id=000ab2e4f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <div className="indicates-required">
              <span className="asterisk">*</span> markiert Pflichtfeld
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                E-Mail-Adresse <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                className="required email form-control"
                id="mce-EMAIL"
                required=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-ANREDE">Anrede </label>
              <input
                type="text"
                name="ANREDE"
                className="text form-control"
                id="mce-ANREDE"
                value={anrede}
                onChange={(e) => setAnrede(e.target.value)}
              />
            </div>            
            <div className="mc-field-group">
              <label htmlFor="mce-FNAME">Vorname </label>
              <input
                type="text"
                name="FNAME"
                className="text form-control"
                id="mce-FNAME"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-LNAME">Nachname </label>
              <input
                type="text"
                name="LNAME"
                className="text form-control"
                id="mce-LNAME"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            {/* <p>
              <a href="https://us7.campaign-archive.com/home/?u=b3cb9b6cd4aece0744754a36c&amp;id=3730d8c389">
                View previous campaigns
              </a>
            </p> */}
            <div
              id="mergeRow-gdpr"
              className="mergeRow gdpr-mergeRow content__gdprBlock mc-field-group"
            >
              <div className="content__gdpr">
                <label className="mb-2">Marketing Permissions: Wie dürfen wir Sie kontaktieren?</label>                
                <fieldset
                  className="mc_fieldset gdprRequired mc-field-group"
                  name="interestgroup_field"
                >
                  <label className="checkbox subfield" htmlFor="gdpr365926">
                    <input
                      type="checkbox"
                      id="gdpr_365926"
                      name="gdpr[365926]"
                      className="gdpr"
                      value="Y"
                    />
                    <span>E-Mail</span>
                  </label>
                </fieldset>
                <p className="mt-4">
                  Sie können sich jederzeit abmelden, indem Sie auf den Link in
                  der Fußzeile unserer E-Mails klicken. Informationen zu unseren
                  Datenschutzpraktiken finden Sie auf unserer Website.
                </p>
              </div>
              <div className="content__gdprLegal">
                <p>
                Wir nutzen Mailchimp als unsere Marketing-Plattform. Durch Klicken auf 'Abonnieren' bestätigen Sie, dass Ihre Daten zur Verarbeitung an Mailchimp übermittelt werden.{" "}
                  <a href="https://mailchimp.com/legal/terms">Erfahren Sie mehr</a>{" "}
                  über die Datenschutzpraktiken von Mailchimp.
                </p>
              </div>
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              <input
                type="text"
                name="b_b3cb9b6cd4aece0744754a36c_3730d8c389"
                tabIndex="-1"
                defaultValue=""
              />
            </div>
            <div className="clear">
              {/* <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
                value="Subscribe"
              /> */}
              <button type="submit" className="q-btn q-btn-primary">Gratis abonnieren</button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MailchimpSignupForm;